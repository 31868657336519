import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Collapse,
  List,
  ListItem,
  Card,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

// Import Firebase app (assuming it's initialized in App.js)
import { firebaseApp } from '../App'; // Adjust the import path as needed

function SearchPage() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]); // Store original results
  const [loading, setLoading] = useState(false);
  const [analyzing, setAnalyzing] = useState(false); // New state for AI analysis
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth(firebaseApp);

  // State for expanded message IDs with separate fields
  const [expandedMessageIds, setExpandedMessageIds] = useState({});

  // Memoized counts for each tab category
  const accountCount = useMemo(
    () => results.filter((r) => r.partitionKey && r.partitionKey.startsWith('email_')).length,
    [results]
  );
  const discussionCount = useMemo(
    () => results.filter((r) => r.partitionKey && r.partitionKey.startsWith('text_')).length,
    [results]
  );
  const ipCount = useMemo(
    () => results.filter((r) => r.partitionKey && r.partitionKey.startsWith('ip_')).length,
    [results]
  );
  const phoneCount = useMemo(
    () => results.filter((r) => r.partitionKey && r.partitionKey.startsWith('phone_')).length,
    [results]
  );

  // Automatically select the tab with the most results after search
  useEffect(() => {
    if (hasSearched) {
      const counts = [
        { type: 0, count: accountCount },
        { type: 1, count: discussionCount },
        { type: 2, count: ipCount },
        { type: 3, count: phoneCount },
      ];

      // Sort tabs by the number of results, descending
      counts.sort((a, b) => b.count - a.count);

      // Filter out categories with zero results
      const filteredCounts = counts.filter((c) => c.count > 0);

      if (filteredCounts.length > 0) {
        // Automatically select the tab with the most results
        setActiveTab(filteredCounts[0].type);
      } else {
        // Default to first tab or handle as needed
        setActiveTab(0);
      }
    }
  }, [accountCount, discussionCount, ipCount, phoneCount, hasSearched]);

  // Updated getContentString function
  const getContentString = (content) => {
    if (Array.isArray(content)) {
      return content.join(', '); // Combine array items into a single string
    }
    if (typeof content === 'string' && content.trim() !== '') {
      return content;
    }
    return 'N/A'; // Return 'N/A' if content is not present or empty
  };

  const handleSearch = async () => {
    if (!query.trim()) {
      setError('Please enter a search term.');
      return;
    }

    setLoading(true);
    setError('');
    setResults([]);
    setOriginalResults([]);
    setHasSearched(true);

    try {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      const response = await axios.get(`https://w2lw8tk4-3005.uks1.devtunnels.ms/api/search`, {
        params: { q: query, userId }, // Pass userId if available
      });
      console.log('Search Results:', response.data.results); // Log results here
      setResults(response.data.results);
      setOriginalResults(response.data.results); // Store original results
    } catch (err) {
      console.error(err);
      if (err.response) {
        setError(
          `Error: ${err.response.status} - ${
            err.response.data.message || 'An error occurred while fetching search results.'
          }`
        );
      } else if (err.request) {
        setError('Network error: Please check your internet connection.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Trigger search when the "Enter" key is pressed
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // Handle manual tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Function to handle expand/collapse for text and content
  const handleToggleExpand = (messageId, field) => {
    setExpandedMessageIds((prev) => ({
      ...prev,
      [messageId]: {
        ...prev[messageId],
        [field]: !prev[messageId]?.[field],
      },
    }));
  };

  // Function to handle AI Analysis
  const handleAnalyze = async () => {
    if (!originalResults.length) {
      setError('No results to analyze.');
      return;
    }

    setAnalyzing(true);
    setError('');

    try {
      const user = auth.currentUser;
      if (!user) {
        setError('User not authenticated. Please log in.');
        setAnalyzing(false);
        navigate('/login'); // Redirect to login page
        return;
      }

      const token = await user.getIdToken();

      const response = await axios.post(
        `https://w2lw8tk4-3005.uks1.devtunnels.ms/api/analyze`,
        {
          searchQuery: query,
          results: originalResults,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        const { filteredResults, summary } = response.data;
        if (filteredResults) {
          setResults(filteredResults);
          // Optionally set summary state to display it
          // setSummary(summary);
        } else {
          setError('AI analysis failed to return results.');
        }
      } else {
        setError('AI analysis failed to return results.');
      }
    } catch (err) {
      console.error(err);
      if (err.response) {
        setError(
          `AI Analysis Error: ${err.response.status} - ${
            err.response.data.message || 'An error occurred during AI analysis.'
          }`
        );
      } else if (err.request) {
        setError('Network error: Please check your internet connection.');
      } else {
        setError('An unexpected error occurred during AI analysis.');
      }
    } finally {
      setAnalyzing(false);
    }
  };

  // Helper function to display compromised accounts, IPs, and phones in a black table
  const renderTable = (data, headers) => (
    <TableContainer component={Paper} sx={{ backgroundColor: '#000', width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: '#000' }}>
            {headers.map((header) => (
              <TableCell key={header} sx={{ color: '#fff' }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={idx} sx={{ backgroundColor: '#000' }}>
              {Object.values(row).map((val, idx) => (
                <TableCell key={idx} sx={{ color: '#fff' }}>
                  {val}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Helper function to display discussions in a card format with text truncation
  const renderDiscussionCard = (data) => {
    const isTextExpanded = expandedMessageIds[data.message_id]?.textExpanded || false;
    const isContentExpanded = expandedMessageIds[data.message_id]?.contentExpanded || false;

    const MAX_TEXT_LENGTH = 200;
    const isTextLong = data.text.length > MAX_TEXT_LENGTH;
    const displayedText = isTextLong && !isTextExpanded ? data.text.slice(0, MAX_TEXT_LENGTH) + '...' : data.text;

    return (
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#2B2B2B',
          color: '#fff',
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
            Hacker Discussion
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>
            {displayedText}
          </Typography>
          {isTextLong && (
            <Button
              variant="text"
              onClick={() => handleToggleExpand(data.message_id, 'textExpanded')}
              sx={{ color: '#0071E3', padding: 0, textTransform: 'none', marginTop: 1 }}
            >
              {isTextExpanded ? 'Show less' : 'Read more'}
            </Button>
          )}
          <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#888', marginTop: 1 }}>
            Channel: {data.channel_username}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#888' }}>
            Date: {data.date}
          </Typography>
        </CardContent>

        {/* Expand Button for Content */}
        {data.content && data.content !== 'N/A' && (
          <Box sx={{ padding: 2 }}>
            <Button
              variant="text"
              onClick={() => handleToggleExpand(data.message_id, 'contentExpanded')}
              sx={{ color: '#0071E3', padding: 0, textTransform: 'none' }}
            >
              {isContentExpanded ? 'Collapse' : 'Expand'}
            </Button>
            <Collapse in={isContentExpanded} timeout="auto" unmountOnExit>
              <Typography variant="body2" sx={{ color: '#fff', marginTop: 1 }}>
                {data.content}
              </Typography>
            </Collapse>
          </Box>
        )}
      </Card>
    );
  };

  // Categorize and display results based on partitionKey
  const categorizeResult = (item) => {
    const {
      partitionKey,
      email,
      content, // Changed from contents to content
      text,
      ip_address,
      phone_number,
      channel_username,
      date,
      message_id,
    } = item;

    // Helper to safely join contents (now 'content' is a single field)
    // const getContentString = (contentsArray) => {
    //   if (Array.isArray(contentsArray)) {
    //     return contentsArray.join(', ');
    //   }
    //   return 'N/A';
    // };

    if (partitionKey && partitionKey.startsWith('email_')) {
      return renderTable(
        [{ Email: email || 'N/A', Content: getContentString(content), Date: date || 'N/A' }],
        ['Email', 'Content', 'Date']
      );
    } else if (partitionKey && partitionKey.startsWith('ip_')) {
      return renderTable(
        [
          {
            Message: text || 'N/A',
            Content: getContentString(content),
            Date: date || 'N/A',
            Channel: channel_username || 'N/A',
          },
        ],
        ['Message', 'Content', 'Date', 'Channel']
      );
    } else if (partitionKey && partitionKey.startsWith('phone_')) {
      return renderTable(
        [
          {
            'Phone Number': phone_number || 'N/A',
            Content: getContentString(content),
            Date: date || 'N/A',
            Channel: channel_username || 'N/A',
          },
        ],
        ['Phone Number', 'Content', 'Date', 'Channel']
      );
    } else if (partitionKey && partitionKey.startsWith('text_')) {
      return renderDiscussionCard({
        text: text || 'N/A',
        channel_username: channel_username || 'N/A',
        date: date || 'N/A',
        content: content || 'N/A', // Changed from contents to content
        message_id: message_id || `msg-${Math.random()}`,
      });
    } else {
      return (
        <Typography variant="body2" sx={{ color: '#fff' }}>
          Unknown Result Type
        </Typography>
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: hasSearched ? 'flex-start' : 'center',
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: '#181818',
        padding: 4,
        boxSizing: 'border-box',
      }}
    >
      {!hasSearched && (
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            mb: 4,
            background: 'linear-gradient(45deg, #FF6B6B, #FFD93D, #6BCB77, #4D96FF)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
          }}
        >
          Pardx Search Engine
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: hasSearched ? 'flex-end' : 'center',
          width: '100%',
          maxWidth: 800,
          mb: hasSearched ? 4 : 6,
          borderRadius: hasSearched ? '50px 0 0 50px' : '50px',
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
          position: hasSearched ? 'fixed' : 'static',
          top: hasSearched ? 20 : 'auto',
          zIndex: hasSearched ? 1000 : 'auto',
          backgroundColor: hasSearched ? '#282828' : 'transparent',
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Enter your search query..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          fullWidth
          sx={{
            backgroundColor: '#282828',
            borderRadius: hasSearched ? '50px 0 0 50px' : '50px',
            input: {
              padding: '18px 20px',
              fontSize: '1.3rem',
              color: '#ffffff',
              backgroundColor: '#282828',
            },
            '&::placeholder': {
              color: '#888',
            },
            '&:hover': {
              boxShadow: '0px 0px 12px rgba(0, 113, 227, 0.5)',
              backgroundColor: '#282828',
            },
            '&.Mui-focused': {
              backgroundColor: '#282828',
              boxShadow: '0px 0px 12px rgba(0, 113, 227, 0.4)',
              borderColor: '#0071E3',
            },
            '& fieldset': {
              border: 'none',
            },
          }}
        />
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 4, width: '100%', maxWidth: 800 }}>
          {error}
        </Alert>
      )}

      {/* Display tabs and result area only after search */}
      {hasSearched && (
        <Box sx={{ width: '100%', maxWidth: 1200, mt: 10 }}>
          {/* Tabs for different result types */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            aria-label="result type tabs"
            sx={{
              backgroundColor: '#1E1E1E',
              borderRadius: '8px 8px 0 0',
              '& .MuiTab-root': {
                minWidth: 'auto',
                padding: '2px 8px',
                textTransform: 'none',
                fontWeight: 'bold',
                color: '#fff',
                fontSize: '0.75rem',
                flexGrow: 0,
                flexShrink: 1,
                margin: '0 4px',
              },
              '& .MuiTabs-flexContainer': {
                flexWrap: 'nowrap',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#0071E3',
              },
            }}
          >
            <Tab label={`Accounts (${accountCount})`} aria-label="Accounts Tab" />
            <Tab label={`Discussions (${discussionCount})`} aria-label="Discussions Tab" />
            <Tab label={`IPs (${ipCount})`} aria-label="IPs Tab" />
            <Tab label={`Phones (${phoneCount})`} aria-label="Phones Tab" />
          </Tabs>

          {/* Black background container for results */}
          <Box
            sx={{
              width: '100%',
              backgroundColor: '#1E1E1E',
              padding: 4,
              borderRadius: '0 0 8px 8px',
              mt: 0,
            }}
          >

            {/* Display the results for the active tab */}
            <Grid container spacing={4}>
              {results.length > 0 &&
                results
                  .filter((item) => {
                    if (!item.partitionKey) return false;
                    switch (activeTab) {
                      case 0:
                        return item.partitionKey.startsWith('email_');
                      case 1:
                        return item.partitionKey.startsWith('text_');
                      case 2:
                        return item.partitionKey.startsWith('ip_');
                      case 3:
                        return item.partitionKey.startsWith('phone_');
                      default:
                        return true;
                    }
                  })
                  .map((item) => {
                    // Determine Grid item size based on result type
                    const isDiscussion = item.partitionKey && item.partitionKey.startsWith('text_');
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={isDiscussion ? 6 : 12}
                        md={isDiscussion ? 4 : 12}
                        key={item.id || item.message_id || Math.random()}
                      >
                        {categorizeResult(item)}
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SearchPage;
