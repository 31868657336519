// src/pages/Dashboard.js

import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  useTheme,
  Tooltip,
  Drawer,
  Fab,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import RecentCVE from './Components/RecentCVE';
import HeatMap from './Components/HeatMap';
import FindingsTable from './Components/FindingsTable';
import OverviewCard from './Components/OverviewCard';
import ThreatFeed from './Components/ThreatFeed';
// Removed GeneratePdfButton import as per instructions
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GaugeChart from './Components/GaugeChart';
import BrandProtection from './Components/BP'; // Import BrandProtection from BP.js
import NetworkVulnerabilities from './Components/Network';
import { styled } from '@mui/material/styles'; // Corrected import

// Utility function to standardize detection findings
const mapFindingToStandardFormat = (finding) => {
  let date, source, type, detail, id;
  if (finding.dateOfDetection && finding.port) {
    id = finding.id;
    date = finding.dateOfDetection;
    source = 'Network Scan';
    type = `Open Port - ${finding.port}`;
    detail = finding.detail;
  } else if (finding.date && finding.partitionKey) {
    id = finding.id;
    date = finding.date;
    source = finding.channel_name || 'Unknown Source';
    type = 'Compromised credentials';
    detail = finding.content || 'No details available';
  } else {
    id = finding.id || 'Unknown';
    date = finding.detectionDate || 'Unknown';
    source = 'Unknown Source';
    type = 'Unknown Type';
    detail = 'No details available';
  }
  return { id, date, source, type, detail };
};

// Styled component for main content area
const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: '#000', // Black background
}));

export default function Dashboard({ data, loading }) {
  const theme = useTheme();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // State to control the visibility of the ThreatFeed Drawer
  const [threatFeedOpen, setThreatFeedOpen] = useState(false);

  const toggleThreatFeed = (open) => () => {
    setThreatFeedOpen(open);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // No data available state
  if (!data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h5" color="white">
          No data available at the moment. Please check back later.
        </Typography>
      </Box>
    );
  }

  // Destructure data for easier access
  const {
    companyDomain = 'Domain not set',
    detections = [],
    searches = [],
    vulnerabilities = [],
    similarDomains = [],
    similarDomainsCount = similarDomains.length, // Fallback to length if count isn't provided
    healthStatus = 'Unknown',
    lastLogin = 'Never',
    isFirstLogin = true,
  } = data;

  // Correct extraction of userIndustry from 'industry' field in Firebase
  const userIndustry = data?.industry || '';
  console.log('User Industry:', userIndustry);
  
  // Standardize detection findings
  const standardizedFindings = detections.map(mapFindingToStandardFormat);

  // Calculate report counts
  const reportCount = searches;
  const detectionCount = detections.length;

  // Filter detections based on selected year
  const filteredData = detections.filter((finding) => new Date(finding.date).getFullYear() === selectedYear);

  // Extract unique years for dropdown
  const years = [...new Set(detections.map((finding) => new Date(finding.date).getFullYear()))];

  // Handle PDF generation (Removed as per instructions)
  // const handleGeneratePdf = () => {
  //   console.log('Generate PDF for domain:', companyDomain);
  //   // Implement PDF generation logic here
  // };

  return (
    <MainContent>
      <Grid container spacing={3}>
        {/* Top Section: Overview Cards */}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} md={3}>
            <OverviewCard 
              title="Detected Breaches Count" 
              value={detectionCount.toLocaleString()} // Format with commas
              icon={<BusinessIcon />} 
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <OverviewCard 
              title="Number of Searches"
              value={reportCount.toLocaleString()} // Format with commas
              icon={<SecurityIcon />} 
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <OverviewCard 
              title="Monitoring Status" 
              value="Active" 
              icon={<AccessTimeIcon />} 
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <OverviewCard 
              title="Potential Threats Monitored" 
              value={similarDomainsCount.toLocaleString()} // Formatted with commas
              icon={<AccessTimeIcon />} 
            />
          </Grid>
        </Grid>

        {/* Section: Recent Events & Detections */}
        <Grid container item xs={12} spacing={3}>
          {/* RecentCVE: Recent Events and Detections */}
          <Grid item xs={12}>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" gutterBottom color="white">
                  Recent Events & Detections
                </Typography>
                <Tooltip title="Latest events and detections across the platform." arrow>
                  <InfoIcon sx={{ color: 'grey', marginLeft: 1, fontSize: '1.2rem' }} />
                </Tooltip>
              </Box>
              <RecentCVE detections={detections} />
            </Box>
          </Grid>
        </Grid>

        {/* Second Section: Brand Protection
        <Grid container item xs={12} spacing={3}>
          {/* Brand Protection 
          <Grid item xs={12}>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" gutterBottom color="white">
                  Brand Protection
                </Typography>
                <Tooltip title="Monitoring for potential threats to your brand, including phishing and domain issues." arrow>
                  <InfoIcon sx={{ color: 'grey', marginLeft: 1, fontSize: '1.2rem' }} />
                </Tooltip>
              </Box>
              <BrandProtection detections={detections} />
            </Box>
          </Grid>
        </Grid>
        */}
        {/* Third Section: Telegram Monitoring */}
        <Grid container item xs={12} spacing={3}>
          {/* Telegram Monitoring */}
          <Grid item xs={12}>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" gutterBottom color="white">
                  Telegram Monitoring
                </Typography>
                <Tooltip title="Monitoring Telegram for VIP protection and other security events." arrow>
                  <InfoIcon sx={{ color: 'grey', marginLeft: 1, fontSize: '1.2rem' }} />
                </Tooltip>
              </Box>
              <FindingsTable findings={standardizedFindings} />
            </Box>
          </Grid>
        </Grid>

        {/* Fifth Section: Incident HeatMap */}
        <Grid container item xs={12} spacing={3}>
          {/* Incident HeatMap */}
          <Grid item xs={12}>
            <Box>
              <Box display="flex" alignItems="center" mb={1}>
              </Box>
              <HeatMap />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainContent>
  );
}
