// src/components/ThreatFeed.js

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { v4 as uuidv4 } from 'uuid';

// Styled Components

// Chat Container matching the window's background
const ChatContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#000', // Black background to match the window
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%', // Occupy full height of the Drawer
  boxShadow: 'none', // Remove shadow for a flatter design
}));

// Container for messages with scrollable content
const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1), // Prevent scrollbar overlapping
}));

// Chat bubbles for user and AI messages
const MessageBubble = styled(Paper)(({ theme, isUser }) => ({
  backgroundColor: isUser ? '#36454F' : '#36454F', // Darker shades for better contrast
  color: '#fff',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  maxWidth: '75%',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  boxShadow: 'none', // Remove inner shadow for a cleaner look
}));

// Container for the input area with rounded corners
const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  borderTop: '1px solid #555', // Subtle border to separate from messages
  borderRadius: '12px', // Rounded corners
  backgroundColor: '#1a1a1a', // Slightly lighter background for input area
}));

// Timestamp styling
const Timestamp = styled(Typography)(({ theme }) => ({
  color: '#bbb', // Light gray for the timestamp
  fontSize: '0.75rem',
  textAlign: 'right',
  marginTop: theme.spacing(0.5),
}));

const ThreatFeed = () => {
  const [messages, setMessages] = useState([
    {
      id: uuidv4(),
      text: 'Hello! I am your AI Assistant. How can I help you today?',
      isUser: false,
      timestamp: new Date(),
    },
  ]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  // Scroll to bottom whenever messages update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Function to handle sending a message
  const sendMessage = () => {
    if (input.trim() === '') return;

    const userMessage = {
      id: uuidv4(),
      text: input,
      isUser: true,
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput('');

    // Simulate AI response after a short delay
    setTimeout(() => {
      const aiResponses = [
        "I'm here to help! You can ask me about any threats or findings on the platform.",
        'Could you please provide more details about your query?',
        'Here are some recent threats detected on your platform...',
        'Let me explain that threat in more detail for you.',
        'Sure, I can guide you through that.',
      ];
      const randomResponse = aiResponses[Math.floor(Math.random() * aiResponses.length)];

      const aiMessage = {
        id: uuidv4(),
        text: randomResponse,
        isUser: false,
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, aiMessage]);
    }, 1000);
  };

  // Function to handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <ChatContainer>
      <Typography variant="h6" color="white" gutterBottom>
        AI Assistant
      </Typography>
      <Divider sx={{ backgroundColor: '#555', marginBottom: 2 }} />
      <MessagesContainer>
        <List disablePadding>
          {messages.map((message) => (
            <ListItem key={message.id} disableGutters>
              <Box display="flex" flexDirection="column" width="100%">
                <MessageBubble elevation={0} isUser={message.isUser}>
                  <ListItemText primary={message.text} />
                </MessageBubble>
                <Timestamp>
                  {message.timestamp.toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Timestamp>
              </Box>
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </MessagesContainer>
      <InputContainer>
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          fullWidth
          multiline
          maxRows={4}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            style: { backgroundColor: '#1a1a1a', color: '#fff' },
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#555',
                borderRadius: '12px', // Rounded corners for input field
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#777',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#4a90e2',
              },
              color: '#fff',
            },
          }}
        />
        <IconButton
          color="primary"
          onClick={sendMessage}
          sx={{
            backgroundColor: '#000', // Black background for send button
            width: '40px',
            height: '40px',
            '&:hover': {
              backgroundColor: '#333', // Slightly lighter on hover
            },
          }}
        >
          <SendIcon sx={{ color: '#fff' }} /> {/* White icon for contrast */}
        </IconButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default ThreatFeed;
