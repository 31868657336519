import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const GaugeChart = ({ score, maxScore, label }) => {
    const data = {
        datasets: [
            {
                data: [score, maxScore - score],
                backgroundColor: [
                    score > (0.75 * maxScore) ? '#00d500' : score > (0.5 * maxScore) ? 'yellow' : 'red',
                    '#e0e0e0',
                ],
                borderWidth: 0,
            },
        ],
        labels: [label],
    };

    const options = {
        cutout: '80%',
        rotation: -90,
        circumference: 180,
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto', position: 'relative' }}>
            <Doughnut data={data} options={options} />
            <div style={{
                position: 'absolute',
                top: '68%',
                left: '50%',
                transform: 'translate(-50%, -60%)', // Adjusted to move the text slightly lower
                textAlign: 'center'
            }}>
                <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                    {label}
                </div>
                <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '10px' }}>
                    {score}/{maxScore}
                </div>
            </div>
        </div>
    );
};

export default GaugeChart;
